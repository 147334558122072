import classNames from "classnames"
import * as React from "react"
import Card from "../Card"
import { AcceptIcon, AnalizeIcon, ManualIcon } from "../Icons"

import * as styles from './styles.module.scss'

const cards = [
  {
    id: 1,
    text: "We accept complaints from people",
    icon: <AcceptIcon />
  },
  {
    id: 2,
    text: "Manual network analysis for negative reviews",
    icon: <ManualIcon />
  },
  {
    id: 3,
    text: "We analyze the market due to our software",
    icon: <AnalizeIcon />
  },
]

const CardsList = () => {
  return (
    <div className={styles.cards}>
      {cards.map(card => <Card {...card} key={card.id} />)}
    </div>
  )
}

export default CardsList