import classNames from "classnames";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react"
import CardsList from "../components/atoms/CardsList";
import { CameraIcon, ListIcon } from "../components/atoms/Icons";
import ContactForm from "../components/ContactForm";
import Header from "../components/Header";
import SEO from "../components/SEO";

import '../styles/globals.scss'
import * as styles from './styles.module.scss'

const IndexPage = () => {
  return (
    <main>
      <Header />
      <section className={styles.firstBlock} id="heroBlock">
        <div className={styles.container}>
          <div className={styles.content}>
            <h1 className={styles.title}>
              We are a company, which fight with violations in the Fintech field.
            </h1>
          </div>
        </div>
        <div className={styles.images}>
          <StaticImage className={styles.image} placeholder="blurred" quality={100} src="../images/first-bg1.png" alt="first-bg" />
          <StaticImage className={styles.imageMobile} placeholder="blurred" quality={100} src="../images/web-security1.png" alt="web-security" />
        </div>
      </section>

      <section className={styles.ourToolsBlock} id="ourToolsBlock">
        <div className={styles.container}>
          <div className={styles.content}>
            <h2 className={styles.title}>
              How do we find out about violations in the Fintech field
            </h2>
            <CardsList />
          </div>
        </div>
      </section>

      <section className={styles.howWeWorkBlock} id="howWeWorkBlock">
        <div className={styles.container}>
          <div className={styles.content}>
            <h2 className={styles.title}>
              In the case we find out any violation, there are stages of our actions
            </h2>
            <div className={styles.howWeWork}>
              <StaticImage className={styles.server} placeholder="blurred" quality={100} src="../images/server.png" alt="server" />
              <div className={styles.cases}>
                <div className={styles.item}>
                  <div className={styles.number}>1.</div>
                  <div className={styles.text}>We contact with product to eliminate the violation</div>
                </div>
                <div className={styles.item}>
                  <div className={styles.number}>2.</div>
                  <div className={styles.text}>We contact with the bank or electronic wallet, that were involved in the process of accepting payments</div>
                </div>
                <div className={styles.item}>
                  <div className={styles.number}>3.</div>
                  <div className={styles.text}>Contact with local Law Enforcement.</div>
                </div>
                <div className={styles.item}>
                  <div className={styles.number}>4.</div>
                  <div className={styles.text}>We contact  with the central bank of the country.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.servicesProvidedBlock} id="servicesProvided">
        <div className={styles.container}>
          <div className={styles.content}>
            <h2 className={styles.title}>
              Services provided by the project:
            </h2>
            <div className={styles.cards}>
              <div className={styles.card}>
                <div className={styles.cardTop}>
                  <ListIcon />
                  Checking a merchant<br/> on request for violations
                </div>
                <div className={styles.cases}>
                  <div className={styles.item}>
                    <div className={styles.number}>1.</div>
                    <div className={styles.text}>Checking for live acquiring</div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.number}>2.</div>
                    <div className={styles.text}>Checking the website for live traffic</div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.number}>3.</div>
                    <div className={styles.text}>Checking the website for the legitimacy of their services</div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.number}>4.</div>
                    <div className={styles.text}>Conducting control purchases, test payments</div>
                  </div>
                </div>
                <div className={styles.cardBottom}>
                  Providing structured information for each incident and the overall total for the merchant
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTop}>
                  <CameraIcon />
                  External monitoring system
                </div>
                <div className={styles.cases}>
                  <div className={styles.item}>
                    <div className={styles.number}>1.</div>
                    <div className={styles.text}>Checking for the usage of bank accounts, for illegal purposes</div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.number}>2.</div>
                    <div className={styles.text}>Collection of a list of accounts used for illegal purposes, performance of control purchases</div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.number}>3.</div>
                    <div className={styles.text}>Collecting detailed information on the merchant</div>
                  </div>
                </div>
                <div className={styles.cardBottom}>
                  Providing structured information on each account and merchants where they are used
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.contactBlock} id="contactBlock">
        <div className={styles.container}>
          <div className={styles.content}>
            <ContactForm />
            <a className={styles.email} href="mailto:support@fntcyber.com">support@fntcyber.com</a>
          </div>
        </div>
        <div className={styles.images}>
          <StaticImage className={styles.image} placeholder="blurred" quality={100} src="../images/contact-bg.jpeg" alt="contact-bg" />
        </div>
      </section>
    </main>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)