// extracted by mini-css-extract-plugin
export var card = "styles-module--card--dpAXZ";
export var cardBottom = "styles-module--cardBottom--5akBe";
export var cardTop = "styles-module--cardTop--m3fCq";
export var cards = "styles-module--cards--MQn8L";
export var cases = "styles-module--cases--WhJKE";
export var contactBlock = "styles-module--contactBlock--xoFag";
export var container = "styles-module--container--s3Y9b";
export var content = "styles-module--content--I3nnS";
export var email = "styles-module--email--1XjW1";
export var firstBlock = "styles-module--firstBlock--E4cIw";
export var howWeWork = "styles-module--howWeWork--TEaMq";
export var howWeWorkBlock = "styles-module--howWeWorkBlock--5LslY";
export var image = "styles-module--image--z0Ifj";
export var imageMobile = "styles-module--imageMobile--74Rag";
export var images = "styles-module--images--urCPP";
export var item = "styles-module--item--de48g";
export var number = "styles-module--number--dkulp";
export var ourToolsBlock = "styles-module--ourToolsBlock--pzYnq";
export var server = "styles-module--server--r-J61";
export var servicesProvidedBlock = "styles-module--servicesProvidedBlock--kuBv4";
export var text = "styles-module--text--FYV2T";
export var title = "styles-module--title--DORzR";