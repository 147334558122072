import React, { FormEvent, useState }  from "react"
import axios from "axios";

import * as styles from './styles.module.scss'

interface IStatus {
  ok?: boolean,
  msg?: string
}

const ContactForm = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [serverState, setServerState] = useState<{submitting: boolean, status?: IStatus | null}>({
    submitting: false,
    status: null
  });

  const handleServerResponse = (ok: boolean, loading: boolean, msg: string, form: HTMLFormElement) => {
    setLoading(loading)
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const data: Record<string, any> = {};
    formData.forEach((value, key) => (data[key] = value));
    setLoading(true);
    axios({
      method: "post",
      url: "https://api.fntcyber.com/send/email",
      data,
      headers: {
        'Content-Type': `application/json`
      }
    })
      .then(r => {
        handleServerResponse(true, false, "Successfully sent", form);
      })
      .catch(r => {
        handleServerResponse(false, false, r.response.data.error, form);
      });
  };

  return (
    <div className={styles.contactForm}>
      <h2 className={styles.title}>Contact us</h2>

      <form className={styles.form} onSubmit={handleOnSubmit}>
        <input className={styles.input} type="text" name="name" placeholder="Name" required />
        <input className={styles.input} type="email" name="email" placeholder="Email" required />
        <input className={styles.input} type="text" name="url" placeholder="Url (Optional)" />
        <textarea className={styles.textarea} name="comment" placeholder="Text" required />
        {serverState?.status && serverState?.status?.ok ? (<div className={styles.success}>{serverState?.status?.msg}</div>) : 
        (<button className={styles.button} type="submit" disabled={loading}>{loading ? `Sending...` : `Send a message`}</button>)}
      </form>
    </div>
  )
}
export default ContactForm