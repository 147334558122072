import classNames from "classnames"
import * as React from "react"

import * as styles from './styles.module.scss'

interface IProps {
  id: number
  text: string
  icon: JSX.Element
}
const Card = ({ id, text, icon }: IProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.number}>{id}</div>
      {icon}
      <div className={styles.text}>{text}</div>
    </div>
  )
}

export default Card